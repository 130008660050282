import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

// routes
const routes = [

    { // default config with purchase languages from the localstorage
        path: "/null",
        redirect: "/"
    },
    {
        name: "home-PT",
        path: '/',
        component: () => import("../views/Home"),
    },
    {
        name: 'about-PT',
        path: '/about',
        component: () => import("../views/About")
    },
    {
        name: 'refugees-menu-PT',
        path: '/refugees-menu',
        component: () => import("../views/RefugeesMenu"),
    },
    {
        name: 'terms',
        path: '/terms',
        component: () => import("../views/TermsOfUse")
    },
    {
        name: 'documents-PT',
        path: '/refugees-menu/documents',
        component: () => import("../views/Documents")
    },
    {
        name: 'shelters-PT',
        path: '/refugees-menu/shelters',
        component: () => import("../views/Shelters")
    },
    {
        name: 'jobs-PT',
        path: '/refugees-menu/jobs',
        component: () => import("../views/Jobs")
    },
    {
        name: 'legal-PT',
        path: '/refugees-menu/legal',
        component: () => import("../views/Legal")
    },
    {
        name: 'community-PT',
        path: '/refugees-menu/community',
        component: () => import("../views/Community")
    },
    {
        name: 'emergency-PT',
        path: '/refugees-menu/emergency',
        component: () => import("../views/Emergency")
    },
    {
        name: 'restaurant-PT',
        path: '/refugees-menu/restaurant',
        component: () => import("../views/Restaurant")
    },
    {
        name: 'education-PT',
        path: '/refugees-menu/education',
        component: () => import("../views/Education")
    },
]
const router = new VueRouter({
    routes: routes,
    mode: 'history',
    scrollBehavior(_to, _from, savedPosition) { // to and from do not never usually
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0, behavior: 'smooth' }
        }
    },
})
export default router