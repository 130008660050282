<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "app",
  components: {},
  mounted() {
    if (localStorage.getItem("lang") === null) {
      localStorage.setItem("lang", "pt");
      window.location.reload();
    } else {
      return;
    }
  },
};
</script>

<style >
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
}
*::selection{
  background: #ffa011;
  color: #fff;
}
html,
body,
#app {
  scroll-behavior: smooth;
}
</style>