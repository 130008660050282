import Vue from 'vue'
import App from './App.vue'
import router from './routes/router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FirebaseVue from './firebase'
import i18n from './plugins/i18n'
import VueMask from 'v-mask'
/* import AOS from 'aos' // animate on scroll
import "aos/dist/aos.css" */

library.add(fas, fab)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.use(FirebaseVue)
Vue.use(i18n)
Vue.use(VueMask)
Vue.config.productionTip = false

new Vue({
  /* created() {
    AOS.init() // inicialize animation
  }, */
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
