export default {
    policy: {
        p: "We use our own and third-party cookies that recognize and identify you as a unique user, to ensure a good browsing experience and improve the performance of our website. By continuing to browse our site, you agree to the use of cookies. To learn more, visit our ",
        a: "cookies terms of use page",
        btn: "continue and close"
    },
    toast: {
        h1: "Data sent successfully!",
        h12: "Thanks for the feedback"
    },
    terms: {
        h2: "Terms of use of cookies",
        p1: "A cookie is a small text sent to the browser by the website you visit. With it, the website remembers information about the visit, which facilitates your next access and makes the website more useful for you. For example, we use cookies to remember your preferred language, store and protect your data through forms inserted on our website. It is possible to disable the use of cookies in your browser settings. ",
        span: "What data do we collect?",
        p2: "- Language preference;",
        p3: "- Contact form information;"
    },
    header: {
        li1: "Home",
        li2: "Meet the Project",
        li3: "Refugee assistance",
        li4: "Contact",
    },
    banner: {
        btn: "Meet the Project"
    },
    contact: {
        pForm: "If you are a refugee, fill out the form below and we will help you.",
        spanNome: "What's your name? (optional)",
        spanPais: "What is your country of origin? (mandatory)",
        spanIdade: "How old are you (mandatory)",
        spanTel: "Enter your phone number (optional):",
        spanEmail: "Enter your email (optional):",
        spanGender: "What's your gender? (Required)",
        spanGender2: "Which gender do you most identify with? (Required)",
        genderOpt1: "MAN",
        genderOpt2: "WOMAN",
        genderOpt3: "LGBT",
        btnSubmit: "Submit",
        pWhatsApp: "or contact us directly via WhatsApp.",
        linkWhatsApp: "Open WhatsApp",
        pPartner: "Partner Site:",
        confirmToast: "Submitted successfully!"
    },
    feedback: {
        h1: "EVALUATE OUR WEBSITE",
        btn: "SEND",
        rate5: "I really liked the site! Beautiful and easy to find the information!",
        rate4: "Very good accessibility, usability and design!",
        rate3: "Good Information, Design and Accessibility.",
        rate2: "Design and information can improve...",
        rate1: "Unhelpful information...",
        placeholder: "Write your opinion here...",
        default: "Choose at least one star;)",
        alert: "Thanks for your feedback! ;)"
    },
    footer: {
        pFooter: "© 2021 RioRefugees. Developed by ",
        aName: "Back to the top"
    },
    about: {
        h1: "OUR INITIATIVE",
        p: "Rio Refugees is an initiative that arises from the vision of the need to bring refugees closer to Rio de Janeiro. Like a house with open doors or a guide so that the person knows where to go.",
        link: "Learn more"
    },
    refugeesHistory: {
        h1: "WHO DO WE HELP?",
        p1: "The City Hall of the city of Rio de Janeiro, through Decree 50,187 on 02/14/22, created the Committee on Public Policies for Refugees, Immigrants and Stateless Persons of the Municipality of Rio de Janeiro (Compar-Rio), with the purpose of defending and promote the human rights of people in such situations. Compar-Rio will be under the executive management of the Human Rights Coordination of the Secretariat of Citizenship.",
        p2: "Persons benefiting from the Refugee Assistance Program who meet the criteria established by the decree will receive an amount of R$ 600.00 reais for up to 3 months. The aid may be extended once again for the same period in situations of extreme socioeconomic vulnerability. Inclusion in other assistance benefits does not prevent people from participating in the Refugee Assistance Program, provided they meet the necessary requirements (UNHCR, 2022).",
        span2: "REFUGEES",
        p3: " They are people who are outside their country of origin due to well-founded fears of persecution related to issues of race, religion, nationality, belonging to a particular social group or political opinion, as well as serious and widespread violations of human rights and armed conflicts."
    },
    lastNotices: {
		h1: "LATEST NEWS",
		prefeituraTitle: "News from 02/25/2022",
		prefeituraTexto: "The City Hall of the city of Rio de Janeiro, through Decree 50,187 on 02/14/22, created the Committee on Public Policies for Refugees, Immigrants and Stateless Persons of the Municipality of Rio de Janeiro (Compar-Rio), with the purpose of defending and promote the human rights of people in such situations. Compar-Rio will be under the executive management of the Human Rights Coordination of the Secretariat of Citizenship. Persons benefiting from the Refugee Assistance Program who meet the criteria established by the decree will receive an amount of R$ 600.00 reais for up to 3 months. The aid may be extended once again for the same period in situations of extreme socioeconomic vulnerability. Inclusion in other assistance benefits does not prevent people from participating in the Refugee Assistance Program, provided they meet the necessary requirements (UNHCR, 2022).",
		linkName: "See the full news by clicking here"
	},
    aboutView: {
        h1: "MEET THE PROJECT",
        h21: "ABOUT US",
        p1: "RioRefugees is an initiative that arises from the vision of the need to bring refugees closer to Rio de Janeiro. Like a house with open doors or a guide so that the person knows where to go. Leaving the master's room in development Unisuam's location, this initiative is a combination of affection and knowledge for the refugee. We believe that by offering important information related to Rio de Janeiro, such as: Documentation, education, health, work, housing and culture, in the languages   of the main migratory flows , we contribute to local insertion, reduction of language barriers and more autonomy in the new territory. ",
        h22: "OUR MISSION",
        p2: "Direct people in situations of refuge to find important information, in order to reduce their vulnerability.",
        h23: "VISION OF THE FUTURE",
        p3: "To be a reference in search tools for people in situations of refuge, in need of guidance in the most important contexts for their quality of life, always trying to overcome language barriers.",
        h24: "PROJECT AUTHOR",
        h25: "TEACHER MARIA ISABEL",
        p4: "Master's student in Local Development by the Post-Graduate Program in Local Development at Centro Universitário Augusto Motta, UNISUAM. Graduated in Business Administration, Specialization in HR and Teaching in Higher Education. Substitute teacher at Colégio Federal Pedro II and instructor at SENAC RJ. He has experience in Administration, working mainly on the following subjects: Marketing Management, Production Management, People Management and Entrepreneurship, Research and Innovation.",
    },
    refugeesMenu: {
        h2: "REFUGEE ASSISTANCE",
        p: "On this page, you can find useful information for everyday life as a citizen of Rio de Janeiro.",
        namePage: "Learn more",
        document: "DOCUMENTS",
        jobs: "JOB AND INCOME",
        community: "COMMUNITIES",
        legal: "LEGAL ASSISTANCE",
        shelters: "SHELTERS",
        emergency: "EMERGENCY",
        restaurants: "RESTAURANTS",
        education: "EDUCATION"
    },
    document: {
        titlePage: "DOCUMENT",
        subtitlePage: "Here is information about documents needed by the citizen in Rio de Janeiro",
        link: "Back to previous page",
        linkName: "Click to learn more",
        refugioTitle: "Refuge Request Protocol",
        refugioText: "Refuge request protocol, identity for refugees and asylum seekers, proves the legal situation in the country and entitles the CTPS to expedition. It is issued by the Federal Police.",
        refugioLinkName: "Click here to visit the federal police website",
        ctpsTitle: "CTPS",
        ctpsText: "Employment and social security card is mandatory to obtain regularized work.",
        registroTitle: "National Registry of Foreigners",
        registroText: "Equivalent to RG (General Registry)",
        residenciaTitle: "Permanent residence",
        residenciaText: "Acquired after 4 years residing in Brazil",
        vistoTitle: "Temporary visa and residence permit for Syrians",
        vistoText: "Temporary visa and residence permit for Syrian refugees affected by Syrian armed conflicts are entitled to a residence permit in a more simplified way.",
    },
    jobs: {
        titlePage: "JOB AND INCOME",
        subtitlePage: "Here is information on where to look for a job in Rio de Janeiro",
        linkPage: "Return to the previous page",
        linkText: "Click here to visit the responsible website",
        ministerioTitle: "Ministry of Labor and Employment",
        escolaTitle: "Worker's school and PRONATEC",
        sugestoesTitle: "Some other suggestions",
        trabalhaBrasilTitle: "work Brazil",
        manPowerGroupTitle: "Man Power Group",
        totiTitle: "Toti",
        somamosTitle: "we add",
        sineTitle: "SINE - National Employment System",
        jovemTitle: "Young Apprentice Program in Rio de Janeiro"
    },
    community: {
        titlePage: "COMMUNITY",
        subtitlePage: "Here you will find several communities scattered around Rio de Janeiro",
        linkPage: "Return to the previous page",
        juntosTitle: "Now together",
        refoodgesTitle: "Refoodges",
        refoodgesText: "Refugees involved in gastronomy receive support from professionalization and insertion in the labor market.",
        tamojuntasTitle: "Tamo Juntas",
        aspasTitle: "ASPAS Diocese of Duque de Caxias (ASPAS)",
        aspasText: "Social and philanthropic entity, from the Diocese of Duque de Caxias, responsible for social works and community projects.",
        outrosTitle: "Some other suggestions:",
        outrosLinkName1: "São Vicente de Paulo Association",
        linkName: "Click here to visit the site",
    },
    legal: {
        titlePage: "LEGAL ASSISTANCE",
        subtitlePage: "Here you can find information about locations for legal assistance",
        linkName: "Back to previous page",
        acnurTitle: "United Nations High Commissioner for Refugees",
        acnurLinkName: "Click here to visit the UNHCR website",
        caritasTitle: "Caritas",
        caritasText: "Service Program for Refugees and Refugees Seekers",
        caritasLinkName: "Click here to visit the Caritas website",
        cepremiTitle: "CEPREMI- Community organization",
        cepremiText: "Organization dedicated to the legal assistance of asylum seekers, refugees and immigrants, whose headquarters are in Rio de Janeiro. However, we do provide legal advice to migrants across the country.",
        conareTitle: "CONARE (National Committee for Refugees)",
        conareLinkName: "Click here to learn more about Conare",
        coordenacaoTitle: "General Immigration Coordination",
        coordenacaoLinkName: "Click here to find out more",
        migranteTitle: "Migrante Web",
        migranteLinkName: "Click here to learn more",
        refugiadosTitle: "Refugee Service Center",
        refugiadosText: "The Refugee Service Center helps with documentation and social support procedures for families.",
        ruiTitle: "Foundation house of Rui Barbosa",
        ruiLinkName: "Click to view location",
        crasTitle: "CRAS – Social Assistance Reference Center",
        crasText: "State public unit of reference for the provision of social assistance services, programs and benefits of basic social protection offered under the SUAS-Single Social Assistance System in the municipality of Rio de Janeiro.",
        crasLinkName: "Click here to learn more"
    },
    shelters: {
        titlePage: "SHELTERS",
        subtitlePage: "Here you will find locations for refugee shelters in Rio de Janeiro",
        linkName: "Back to previous page",
        linkName2: "Click to see locations",
        abrigosTitle: "Shelters",
        abrigosText: "Shelter is a temporary place of residence that is sometimes offered by common people or by the government itself. Always try to find out about the organization of the shelter. Here are some shelter addresses indicated by the Google search engine. You also have other possibilities to look for a place to live.",
        republicasTitle: "Republics",
        republicasText: "Aimed at the people who left the reception units and the streets.",
        defensoriaTitle: "Public defense",
        defensoriaText: "It guarantees the right to decent housing and represents low-income citizens in matters related to housing.",
        defesaTitle: "Civil Defense",
        defesaText: "Disaster prevention and assistance actions",
        casaTitle: "My house, My life",
        casaText: "If you want to buy a house, our government has a project called Minha Casa Minha Vida. In it you can achieve your dream of owning a home for a more affordable price. To find out more, look for a bank called Caixa Econômica Federal or what we call Imobiliária (place where properties are sold and rented)",
        acolhidaVTitle: "Pope Francis welcome home",
        acolhidaVText: "Partnership with the PARES Cáritas program",
        acolhidaVLinkName: "Click to learn more",
        creasTitle: "CREAS - Specialized Social Assistance Reference Center",
        creasText: "State public unit with municipal coverage whose role is to be a locus of reference, in the territories, for the provision of specialized social work to families and individuals in situations of personal or social risk, due to violation of rights.",
        creasLinkName: "Click here to visit the site"
    },
    emergency: {
        titlePage: "EMERGENCY",
        subtitlePage: "Here you can find contacts for emergency services in Rio de Janeiro",
        linkName: "Back to previous page",
        linkName2: "Click here to see locations",
        mulheresTitle: "Unit for women",
        mulheresText: "Call 180.",
        policiaTitle: "Police",
        policiaText: "Call 190.",
        ambulanciaTitle: "Public Ambulance",
        ambulanciaText: "Call 192.",
        bombeirosTitle: "Fire Department",
        bombeirosText: "Call 193.",
        saudeTitle: "Dial health",
        saudeText: "Call 136. Monday to Friday (except holidays). SUS accepted.",
        ubsTitle: "UBS - Basic Health Unit",
        ubsText: "Offers consultation with a general practitioner.",
        upaTitle: "UPA - Emergency Care Unit",
    },
    restaurant: {
        titlePage: "RESTAURANTS",
        subtitlePage: "Here you can find restaurant locations in Rio de Janeiro",
        linkPage: "Return to the previous page",
        popularTitle: "Popular restaurants",
        popularText: "These are restaurants where the meal costs from R$ 0.50 and besides being cheap it is very tasty.",
        popularLinkName: "Click here to see restaurants"
    },
    education: {
        titlePage: "EDUCATION",
        subtitlePage: "Here you can find school and college locations in Rio de Janeiro",
        linkPage: "Return to the previous page",
        linkName: "Click here to learn more",
        catedraTitle: "Cátedra Sérgio Vieira de Mello",
        caritasTitle: "Caritas - Refugees in schools",
        pantheonTitle: "Pantheon - Education for Congolese refugees in Duque de Caxias",
        seeducTitle: "Seeduc RJ",
        smeTitle: "SME - Municipal Department of Education",
        smeLinkName: "www.rio.rj.gov.br/web/sme",
        linkName2: "Click to see location",
        csvmTitle: "UNHCR Affiliated Universities"  
    }
}