export default {
        policy: {
                p: "Utilizamos cookies propias y de terceros que lo reconocen e identifican como un usuario único, para garantizar una buena experiencia de navegación y mejorar el rendimiento de nuestro sitio web. Si continúa navegando por nuestro sitio, acepta el uso de cookies. Para obtener más información, visite nuestro ",
                a: " página de condiciones de uso de cookies",
                btn: "continuar y cerrar"
        },
        toast: {
                h1: "¡Datos enviados correctamente!",
                h12: "Gracias por los comentarios"
        },
        terms: {
                h2: "Condiciones de uso de cookies",
                p1: "Una cookie es un pequeño texto enviado al navegador por el sitio web que visita. Con él, el sitio web recuerda información sobre la visita, lo que facilita su próximo acceso y hace que el sitio web sea más útil para usted. Por ejemplo, utilizamos cookies para recordar su idioma preferido, almacenar y proteger sus datos a través de formularios insertados en nuestro sitio web. Es posible deshabilitar el uso de cookies en la configuración de su navegador. ",
                span: "¿Qué datos recopilamos?",
                p2: "- Preferencia de idioma;",
                p3: "- Información del formulario de contacto."
        },
        header: {
                li1: "Inicio",
                li2: "Conoce el proyecto",
                li3: "Asistencia a refugiados",
                li4: "Contacto",
        },
        banner: {
                btn: "Conoce el proyecto"
        },
        contact: {
                pForm: "Si eres un refugiado, llena el formulario a continuación y te ayudaremos",
                spanNome: "¿Cómo te llamas? (opcional)",
                spanPais: "¿Cuál es su país de origen? (obligatorio)",
                spanIdade: "¿Cuántos años tienes (obligatorio)",
                spanTel: "Ingrese su número de teléfono (opcional):",
                spanEmail: "Ingrese su correo electrónico (opcional):",
                spanGender: "¿Cuál es tu género? (Obligatorio)",
                spanGender2: "¿Con qué género te identificas más? (Obligatorio)",
                genderOpt1: "HOMBRE",
                genderOpt2: "MUJER",
                genderOpt3: "LGBT",
                btnSubmit: "Enviar",
                pWhatsApp: "o contáctenos directamente a través de WhatsApp.",
                linkWhatsApp: "Abrir WhatsApp",
                pPartner: "Sitio asociado:",
                confirmToast: "¡Enviado con éxito!"
        },
        feedback: {
                h1: "EVALUAR NUESTRO SITIO",
                btn: "ENVIAR",
                rate5: "¡Me gustó mucho el sitio! ¡Hermoso y fácil de encontrar la información!",
                rate4: "¡Muy buena accesibilidad, usabilidad y diseño!",
                rate3: "Buena información, diseño y accesibilidad",
                rate2: "El diseño y la información pueden mejorar ...",
                rate1: "Información útil ...",
                placeholder: "Escriba aquí su opinión ...",
                default: "Escolha ao menos uma estrela ;)",
                alert: "¡Gracias por tus comentarios!;)"
        },
        footer: {
                pFooter: "© 2021 RioRefugees. Desarrollado por ",
                aName: "Volver al principio"
        },
        about: {
                h1: "NUESTRA INICIATIVA",
                p: "Río Refugiados es una iniciativa que surge de la visión de la necesidad de acercar a los refugiados a Río de Janeiro. Como una casa con las puertas abiertas o un guía para que la persona sepa a dónde ir",
                link: "Más información"
        },
        refugeesHistory: {
                h1: "¿A QUIÉN AYUDAMOS?",
                p1: "El Ayuntamiento de la ciudad de Río de Janeiro, a través del Decreto 50.187 del 14/02/22, creó el Comité de Políticas Públicas para Refugiados, Inmigrantes y Apátridas del Municipio de Río de Janeiro (Compar-Rio), con el objetivo de defender y promover los derechos humanos de las personas en tales situaciones. Compar-Rio estará bajo la dirección ejecutiva de la Coordinación de Derechos Humanos de la Secretaría de Ciudadanía.",
                p2: "Los beneficiarios del Programa de Atención al Refugiado que cumplan con los criterios establecidos por el decreto recibirán un valor de R$ 600,00 reales hasta por 3 meses. La ayuda podrá prorrogarse una vez más por el mismo período en situaciones de extrema vulnerabilidad socioeconómica. La inclusión en otros beneficios de asistencia no impide que las personas participen en el Programa de Asistencia a Refugiados, siempre que cumplan con los requisitos necesarios (ACNUR, 2022).",
                span2: "REFUGIADOS",
                p3: " Son personas que se encuentran fuera de su país de origen por temores fundados de persecución relacionados con cuestiones de raza, religión, nacionalidad, pertenencia a un determinado grupo social u opinión política, así como a violaciones graves y generalizadas de los derechos humanos y armados. conflictos."
        },
        lastNotices: {
		h1: "ULTIMAS NOTICIAS",
		prefeituraTitle: "Noticias del 25/02/2022",
		prefeituraTexto: "El Ayuntamiento de la ciudad de Río de Janeiro, a través del Decreto 50.187 del 14/02/22, creó el Comité de Políticas Públicas para Refugiados, Inmigrantes y Apátridas del Municipio de Río de Janeiro (Compar-Rio), con el objetivo de defender y promover los derechos humanos de las personas en tales situaciones. Compar-Rio estará bajo la dirección ejecutiva de la Coordinación de Derechos Humanos de la Secretaría de Ciudadanía. Los beneficiarios del Programa de Atención al Refugiado que cumplan con los criterios establecidos por el decreto recibirán un valor de R$ 600,00 reales hasta por 3 meses. La ayuda podrá prorrogarse una vez más por el mismo período en situaciones de extrema vulnerabilidad socioeconómica. La inclusión en otros beneficios de asistencia no impide que las personas participen en el Programa de Asistencia a Refugiados, siempre que cumplan con los requisitos necesarios (ACNUR, 2022).",
		linkName: "Retrouvez l'intégralité de l'actualité en cliquant ici"
	},
        aboutView: {
                h1: "ACERCA DEL PROYECTO",
                h21: "ACERCA DE NOSOTROS",
                p1: "RioRefugees es una iniciativa que surge de la visión de la necesidad de acercar a los refugiados a Río de Janeiro. Como una casa con las puertas abiertas o un guía para que la persona sepa a dónde ir. Salir de la habitación del maestro en el desarrollo Ubicación de Unisuam , esta iniciativa es una combinación de cariño y conocimiento para el refugiado. Creemos que al ofrecer información importante relacionada con Río de Janeiro, tales como: Documentación, educación, salud, trabajo, vivienda y cultura, en los idiomas de las principales flujos migratorios, contribuimos a la inserción local, reducción de barreras idiomáticas y mayor autonomía en el nuevo territorio. ",
                h22: "NUESTRA MISIÓN",
                p2: "Dirigir a las personas en situación de refugio a buscar información importante, con el fin de reducir su vulnerabilidad",
                h23: "VISIÓN DE FUTURO",
                p3: "Ser un referente en herramientas de búsqueda para personas en situación de refugio, necesitadas de orientación en los contextos más importantes para su calidad de vida, tratando siempre de superar las barreras del idioma",
                h24: "AUTOR DEL PROYECTO",
                h25: "PROFESSORA MARIA ISABEL",
                p4: "Estudiante de Maestría en Desarrollo Local por el Programa de Postgrado en Desarrollo Local del Centro Universitário Augusto Motta, UNISUAM. Licenciada en Administración de Empresas, Especialización en RRHH y Docencia en Educación Superior. Docente suplente en Colégio Federal Pedro II e instructor en SENAC RJ. Tiene experiencia en el área de Administración, actuando en las siguientes materias: Dirección de Marketing, Dirección de Producción, Dirección de Personas y Emprendimiento, Investigación e Innovación.",
        },
        refugeesMenu: {
                h2: "ASISTENCIA PARA REFUGIADOS",
                p: "En esta página, puede encontrar información útil para la vida cotidiana como ciudadano de Río de Janeiro.",
                namePage: "Más información",
                document: "DOCUMENTOS",
                jobs: "EMPLEO E INGRESOS",
                community: "COMUNIDADES",
                legal: "ASISTENCIA LEGAL",
                shelters: "REFUGIOS",
                emergency: "EMERGENCIA",
                restaurants: "RESTAURANTES",
                education: "EDUCACÍON"
        },
        document: {
                titlePage: "DOCUMENTO",
                subtitlePage: "Aquí hay información sobre los documentos que necesita el ciudadano en Río de Janeiro",
                link: "Volver a la página anterior",
                linkName: "Haz click para aprender mas",
                refugioTitle: "Protocolo de solicitud de refugio",
                refugioText: "Protocolo de solicitud de refugio, identidad para refugiados y solicitantes de asilo, acredita la situación jurídica en el país y habilita al CTPS a expedición. Lo emite la Policía Federal.",
                refugioLinkName: "Haga clic aquí para visitar el sitio web de la policía federal",
                ctpsTitle: "CTPS",
                ctpsText: "La tarjeta de empleo y seguridad social es obligatoria para obtener un trabajo regularizado",
                registroTitle: "Registro Nacional de Extranjeros",
                registroText: "Equivalente a RG (Registro General)",
                residenciaTitle: "Residencia permanente",
                residenciaText: "Adquirida después de 4 años residiendo en Brasil",
                vistoTitle: "Visa temporal y permiso de residencia para sirios",
                vistoText: "La visa temporal y el permiso de residencia para los refugiados sirios afectados por conflictos armados sirios tienen derecho a un permiso de residencia de una manera más simplificada",
        },
        jobs: {
                titlePage: "EMPLEO E INGRESOS",
                subtitlePage: "Aquí hay información sobre dónde buscar trabajo en Río de Janeiro",
                linkPage: "Volver a la página anterior",
                linkText: "Haga clic aquí para visitar el sitio web responsable",
                ministerioTitle: "Ministerio de Trabajo y Empleo",
                escolaTitle: "Escuela de trabajadores y PRONATEC",
                sugestoesTitle: "Algunas otras sugerencias",
                trabalhaBrasilTitle: "trabajo Brasil",
                manPowerGroupTitle: "Man Power Group",
                totiTitle: "Toti",
                somamosTitle: "añadimos",
                sineTitle: "SINE - Sistema Nacional de Empleo",
                jovemTitle: "Programa de jóvenes aprendices en Río de Janeiro"
        },
        community: {
                titlePage: "COMUNIDAD",
                subtitlePage: "Aquí encontrará varias comunidades repartidas por Río de Janeiro",
                linkPage: "Volver a la página anterior",
                juntosTitle: "Ahora juntos",
                refoodgesTitle: "Refoodges",
                refoodgesText: "Los refugiados involucrados en la gastronomía reciben apoyo de la profesionalización e inserción en el mercado laboral.",
                tamojuntasTitle: "Tamo Juntas",
                aspasTitle: "ASPAS Diócesis de Duque de Caxias (ASPAS)",
                aspasText: "Entidad social y filantrópica, de la Diócesis de Duque de Caxias, responsable de obras sociales y proyectos comunitarios.",
                outrosTitle: "Algunas otras sugerencias:",
                outrosLinkName1: "Asociación San Vicente de Paúl",
                linkName: "Haga clic aquí para visitar el sitio",
        },
        legal: {
                titlePage: "ASISTENCIA LEGAL",
                subtitlePage: "Aquí puede encontrar información sobre ubicaciones para obtener asistencia legal",
                linkName: "Volver a la página anterior",
                acnurTitle: "Alto Comisionado de las Naciones Unidas para los Refugiados",
                acnurLinkName: "Haga clic aquí para visitar el sitio web del ACNUR",
                caritasTitle: "Caritas",
                caritasText: "Programa de servicio para refugiados y solicitantes de refugiados",
                caritasLinkName: "Haga clic aquí para visitar el sitio web de Caritas",
                cepremiTitle: "CEPREMI- Organización comunitaria",
                cepremiText: "Organización dedicada a la asistencia legal de solicitantes de asilo, refugiados e inmigrantes, cuya sede se encuentra en Río de Janeiro. Sin embargo, brindamos asesoría legal a migrantes en todo el país.",
                conareTitle: "CONARE (Comité Nacional de Refugiados)",
                conareLinkName: "Haga clic aquí para obtener más información sobre Conare",
                coordenacaoTitle: "Coordinación General de Inmigración",
                coordenacaoLinkName: "Haga clic aquí para obtener más información",
                migranteTitle: "Migrante Web",
                migranteLinkName: "Haga clic aquí para obtener más información",
                refugiadosTitle: "Centro de servicios para refugiados",
                refugiadosText: "El Centro de Servicios para Refugiados ayuda con la documentación y los procedimientos de apoyo social para las familias.",
                ruiTitle: "Casa fundacional de Rui Barbosa",
                ruiLinkName: "Haga clic para ver la ubicación",
                crasTitle: "CRAS - Centro de referencia de asistencia social",
                crasText: "Unidad pública estatal de referencia para la prestación de servicios de asistencia social, programas y beneficios de protección social básica ofrecidos en el SUAS-Sistema Único de Asistencia Social en el municipio de Rio de Janeiro.",
                crasLinkName: "Clic aquí para saber más"
        },
        shelters: {
                titlePage: "REFUGIOS",
                subtitlePage: "Aquí encontrará ubicaciones para refugios de refugiados en Río de Janeiro",
                linkName: "Volver a la página anterior",
                linkName2: "Haga clic para ver las ubicaciones",
                abrigosTitle: "Refugios",
                abrigosText: "El refugio es un lugar de residencia temporal que a veces ofrece la gente común o el propio gobierno. Trate siempre de averiguar sobre la organización del refugio. Aquí hay algunas direcciones de refugios indicadas por el motor de búsqueda de Google. También tienes otras posibilidades para buscar un lugar donde vivir.",
                republicasTitle: "Repúblicas",
                republicasText: "Dirigido a las personas que salieron de las unidades de recepción y las calles.",
                defensoriaTitle: "Defensor público",
                defensoriaText: "Garantiza el derecho a una vivienda digna y representa a los ciudadanos de escasos recursos en materia de vivienda",
                defesaTitle: "Defensa civil",
                defesaText: "Acciones de prevención y atención de desastres",
                casaTitle: "Mi casa, mi vida",
                casaText: "Si desea comprar una casa, nuestro gobierno tiene un proyecto llamado Minha Casa Minha Vida. En él podrás lograr tu sueño de ser propietario de una vivienda por un precio más asequible. Para saber más, busque un banco llamado Caixa Econômica Federal o lo que llamamos Imobiliária (lugar donde se venden y alquilan propiedades)",
                acolhidaVTitle: "Papa Francisco Bienvenido a casa",
                acolhidaVText: "Colaboración con el programa PARES Cáritas",
                acolhidaVLinkName: "Haga clic para obtener más información",
                creasTitle: "CREAS - Centro de Referencia Especializado en Asistencia Social",
                creasText: "Unidad pública estatal con cobertura municipal cuyo rol es ser un locus de referencia, en los territorios, para la prestación de trabajo social especializado a familias e individuos en situaciones de riesgo personal o social, por vulneración de derechos.",
                creasLinkName: "Haga clic aquí para visitar el sitio"
        },
        emergency: {
                titlePage: "EMERGENCIA",
                subtitlePage: "Aquí puede encontrar contactos para los servicios de emergencia en Río de Janeiro",
                linkName: "Volver a la página anterior",
                linkName2: "Haga clic aquí para ver las ubicaciones",
                mulheresTitle: "Unidad para mujeres",
                mulheresText: "Llamar al 180.",
                policiaTitle: "Policía",
                policiaText: "Llamar al 190.",
                ambulanciaTitle: "Ambulancia pública",
                ambulanciaText: "Llamar 192.",
                bombeirosTitle: "Departamento de Bomberos",
                bombeirosText: "Llamar al 193.",
                saudeTitle: "Estado de marcación",
                saudeText: "Llame al 136. De lunes a viernes (excepto festivos). SUS aceptado.",
                ubsTitle: "UBS - Unidad básica de salud",
                ubsText: "Ofrece consulta con un médico de cabecera",
                upaTitle: "UPA - Unidad de atención de emergencia",
        },
        restaurant: {
                titlePage: "RESTAURANTES",
                subtitlePage: "Aquí puede encontrar ubicaciones de restaurantes en Río de Janeiro",
                linkPage: "Volver a la página anterior",
                popularTitle: "Restaurantes populares",
                popularText: "Son restaurantes donde la comida cuesta desde R $ 0,50 y además de barata es muy sabrosa.",
                popularLinkName: "Haga clic aquí para ver restaurantes"
        },
        education: {
                titlePage: "EDUCACIÓN",
                subtitlePage: "Aquí puede encontrar ubicaciones de escuelas y universidades en Río de Janeiro",
                linkPage: "Volver a la página anterior",
                linkName: "Haga clic aquí para obtener más información",
                catedraTitle: "Cátedra Sérgio Vieira de Mello",
                caritasTitle: "Caritas - Refugiados en las escuelas",
                pantheonTitle: "Panteón - Educación para refugiados congoleños en Duque de Caxias",
                seeducTitle: "Seeduc RJ",
                smeTitle: "PYME - Departamento Municipal de Educación",
                smeLinkName: "www.rio.rj.gov.br/web/sme",
                linkName2: "Haga clic para ver la ubicación",
                csvmTitle: "Universidades afiliadas del ACNUR"
        }
}