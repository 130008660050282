import Vue from 'vue'
import VueI18n from 'vue-i18n'

import pt from '../../locales/pt'
import en from '../../locales/en'
import es from '../../locales/es'
import fr from '../../locales/fr'
import ar from '../../locales/ar'

Vue.use(VueI18n)

const messages = {
    pt: pt,
    en: en,
    es: es,
    fr: fr,
    ar: ar
}
export default new VueI18n({
    locale: localStorage.getItem('lang'),
    messages
})