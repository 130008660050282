import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'

export const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyC1DkHUFt81XOFvgvdMklPlNJPXy1wwNss",
    authDomain: "riorefugees-b6d3d.firebaseapp.com",
    databaseURL: "https://riorefugees-b6d3d-default-rtdb.firebaseio.com",
    projectId: "riorefugees-b6d3d",
    storageBucket: "riorefugees-b6d3d.appspot.com",
    messagingSenderId: "211770403586",
    appId: "1:211770403586:web:c9fa32d2919b6bf727701b",
    measurementId: "G-243Z3ENG7X"
})
export default function install(Vue){
    Object.defineProperty(Vue.prototype, '$firebase', {
        get(){
            return firebaseApp
        }
    })
}



