export default {
        policy: {
                p: "نحن نستخدم ملفات تعريف الارتباط الخاصة بنا والجهات الخارجية التي تتعرف عليك وتعرفك كمستخدم فريد ، لضمان تجربة تصفح جيدة وتحسين أداء موقعنا. من خلال الاستمرار في تصفح موقعنا ، فإنك توافق على استخدام ملفات تعريف الارتباط. لمعرفة المزيد ، قم بزيارة ",
                a: "صفحة شروط استخدام ملفات تعريف الارتباط",
                btn: "تواصل وإغلاق"
        },
        toast: {
                h1: "البيانات المرسلة بنجاح!",
                h12: "شكرًا على التعليقات"
        },
        terms: {
                h2: "شروط استخدام ملفات تعريف الارتباط",
                p1: "ملف تعريف الارتباط هو نص صغير يتم إرساله إلى المتصفح من خلال موقع الويب الذي تزوره. باستخدامه ، يتذكر الموقع المعلومات حول الزيارة ، مما يسهل وصولك التالي ويجعل الموقع أكثر فائدة لك. على سبيل المثال ، نستخدم ملفات تعريف الارتباط لتذكر لغتك المفضلة وتخزين بياناتك وحمايتها من خلال النماذج التي يتم إدخالها على موقعنا. من الممكن تعطيل استخدام ملفات تعريف الارتباط في إعدادات متصفحك.",
                span: "ما هي البيانات التي نجمعها؟",
                p2: "- تفضيل اللغات؛",
                p3: "- معلومات نموذج الاتصال."
        },
        header: {
                li1: "الصفحة الرئيسية",
                li2: "تلبية المشروع",
                li3: "مساعدة اللاجئين",
                li4: "جهة اتصال",
        },
        banner: {
                btn: "تلبية المشروع"
        },
        contact: {
                pForm: "إذا كنت لاجئًا ، فاملأ النموذج أدناه وسنساعدك.",
                spanNome: "ما اسمك؟ (اختياري)",
                spanPais: "ما هو بلدك الأصلي؟ (إلزامي)",
                spanIdade: "كم عمرك (إلزامي)",
                spanTel: "أدخل رقم هاتفك (اختياري):",
                spanEmail: "أدخل بريدك الإلكتروني (اختياري):",
                spanGender: "ما هو جنسك؟ (مطلوب)",
                spanGender2: "ما هو الجنس الذي تتعرف عليه أكثر؟ (مطلوب)",
                genderOpt1: "رجال",
                genderOpt2: "امرأة",
                genderOpt3: "LGBT",
                btnSubmit: "إرسال",
                pWhatsApp: "أو اتصل بنا مباشرة عبر WhatsApp.",
                linkWhatsApp: "افتح WhatsApp",
                pPartner: "موقع الشريك:",
                confirmToast: "تم الإرسال بنجاح!"
        },
        feedback: {
                h1: "قم بتقييم موقعنا",
                btn: "إرسال",
                rate5: "أنا حقا أحب الموقع! لطيف وسهل العثور على المعلومات!",
                rate4: "سهولة الوصول وسهولة الاستخدام والتصميم جيدة جدًا!",
                rate3: "معلومات جيدة وتصميم وإمكانية الوصول.",
                rate2: "يمكن تحسين التصميم والمعلومات ...",
                rate1: "القليل من المعلومات المفيدة ...",
                placeholder: "اكتب رأيك هنا ...",
                default: "اختر نجمة واحدة على الأقل ؛)",
                alert: "شكرا لك على ملاحظاتك! ؛)"
        },
        footer: {
                pFooter: "© 2021 RioRefugees. تم التطوير بواسطة ",
                aName: "الرجوع إلى الأعلى"
        },
        about: {
                h1: "مبادرتنا",
                p: "لاجئو ريو هي مبادرة تنبثق من رؤية الحاجة لتقريب اللاجئين من ريو دي جانيرو. مثل منزل بأبواب مفتوحة أو مرشد حتى يعرف الشخص إلى أين يذهب.",
                link: "اعرف المزيد"
        },
        refugeesHistory: {
                h1: "من نساعد؟",
                p1: "أنشأ مجلس مدينة مدينة ريو دي جانيرو ، من خلال المرسوم 50187 بتاريخ 02/14/22 ، لجنة السياسات العامة للاجئين والمهاجرين وعديمي الجنسية في بلدية ريو دي جانيرو (مقارنة ريو) ، لهذا الغرض للدفاع عن حقوق الإنسان وتعزيزها للأشخاص في مثل هذه الحالات. سيكون موقع مقارنة ريو تحت الإدارة التنفيذية لتنسيق حقوق الإنسان التابع لأمانة المواطنة.",
                p2: "الأشخاص المستفيدون من برنامج مساعدة اللاجئين والذين يستوفون المعايير التي حددها المرسوم سيحصلون على مبلغ 600 ريال برازيلي لمدة تصل إلى 3 أشهر. يمكن تمديد المساعدة مرة أخرى لنفس الفترة في حالات الضعف الاجتماعي والاقتصادي الشديد. لا يمنع التضمين في مزايا المساعدة الأخرى الأشخاص من المشاركة في برنامج مساعدة اللاجئين ، بشرط أن يستوفوا المتطلبات اللازمة (مفوضية الأمم المتحدة لشؤون اللاجئين ، 2022).",
                span2: "اللاجئون",
                p3: "هم أشخاص خارج بلدهم الأصلي بسبب مخاوف مبررة من الاضطهاد المتعلق بقضايا العرق أو الدين أو الجنسية أو الانتماء إلى فئة اجتماعية معينة أو الرأي السياسي ، فضلاً عن الانتهاكات الجسيمة والواسعة النطاق لحقوق الإنسان والقوى المسلحة. الصراعات."
        },
        lastNotices: {
		h1: "أحدث الأخبار",
		prefeituraTitle: "أخبار من 02/25/2022",
		prefeituraTexto: "أنشأ مجلس مدينة مدينة ريو دي جانيرو ، من خلال المرسوم 50187 بتاريخ 02/14/22 ، لجنة السياسات العامة للاجئين والمهاجرين وعديمي الجنسية في بلدية ريو دي جانيرو (مقارنة ريو) ، لهذا الغرض للدفاع عن حقوق الإنسان وتعزيزها للأشخاص في مثل هذه الحالات. سيكون موقع مقارنة ريو تحت الإدارة التنفيذية لتنسيق حقوق الإنسان التابع لأمانة المواطنة. الأشخاص المستفيدون من برنامج مساعدة اللاجئين والذين يستوفون المعايير التي حددها المرسوم سيحصلون على مبلغ 600 ريال برازيلي لمدة تصل إلى 3 أشهر. يمكن تمديد المساعدة مرة أخرى لنفس الفترة في حالات الضعف الاجتماعي والاقتصادي الشديد. لا يمنع التضمين في مزايا المساعدة الأخرى الأشخاص من المشاركة في برنامج مساعدة اللاجئين ، بشرط أن يستوفوا المتطلبات اللازمة (مفوضية الأمم المتحدة لشؤون اللاجئين ، 2022).",
		linkName: "شاهد الأخبار كاملة بالضغط هنا"
	},
        aboutView: {
                h1: "حول المشروع",
                h21: "معلومات عنا",
                p1: "RioRefugees هي مبادرة تنبثق من رؤية الحاجة لتقريب اللاجئين من ريو دي جانيرو. كمنزل بأبواب مفتوحة أو كدليل للناس لمعرفة إلى أين يذهبون. ترك غرفة سيد التنمية المحلية Unisuam ، هذه المبادرة هي مزيج من المودة والمعرفة للاجئين. نعتقد أنه من خلال تقديم معلومات مهمة تتعلق بريو دي جانيرو ، مثل: التوثيق والتعليم والصحة والعمل والإسكان والثقافة ، بلغات تدفقات الهجرة الرئيسية ، فإننا المساهمة في الإدراج المحلي ، والحد من حواجز اللغة والمزيد من الاستقلالية في الإقليم الجديد.",
                h22: "مهمتنا",
                p2: "توجيه الأشخاص الموجودين في حالات اللجوء للعثور على معلومات مهمة لتقليل ضعفهم.",
                h23: "الرؤية المستقبلية",
                p3: "أن نكون مرجعًا في أدوات البحث للأشخاص الموجودين في حالات اللجوء ، ويحتاجون إلى التوجيه في أهم السياقات لنوعية حياتهم ، ويحاولون دائمًا التغلب على حواجز اللغة.",
                h24: "مؤلف المشروع",
                h25: "المعلمة ماريا إيزابيل",
                p4: "طالب ماجستير في التنمية المحلية من قبل برنامج الدراسات العليا في التنمية المحلية في Centro Universitário Augusto Motta ، جامعة UNISUAM. شهادة في إدارة الأعمال ، تخصص في الموارد البشرية والتدريس في التعليم العالي. مدرس بديل في Colégio Federal Pedro II ومدرس في SENAC RJ. لديه خبرة في مجال الإدارة ، ويعمل في الموضوعات التالية: إدارة التسويق ، وإدارة الإنتاج ، وإدارة الأفراد وريادة الأعمال ، والبحث والابتكار.",
        },
        refugeesMenu: {
                h2: "مساعدة اللاجئين",
                p: "في هذه الصفحة ، يمكنك العثور على معلومات مفيدة للحياة اليومية كمواطن في ريو دي جانيرو.",
                namePage: "اعرف المزيد",
                document: "مستندات",
                jobs: "وظائفالوظائف والدخل",
                community: "مجتمعات",
                legal: "مساعدة قانونية",
                shelters: "الملاجئ",
                emergency: "حالة طوارئ",
                restaurants: "مطاعم",
                education: "التعليم"
        },
        document: {
                titlePage: "وثيقة",
                subtitlePage: "هذه معلومات حول المستندات التي يحتاجها المواطن في ريو دي جانيرو",
                link: "العودة إلى الصفحة السابقة",
                linkName: "اضغط لتعلم المزيد",
                refugioTitle: "بروتوكول طلب اللجوء",
                refugioText: "البروتوكول الخاص بطلب اللجوء والهوية للاجئين وطالبي اللجوء ، يثبت الوضع القانوني في البلاد ويحق لـ CTPS القيام بالرحلة الاستكشافية. صادرة عن الشرطة الاتحادية ",
                refugioLinkName: "انقر هنا لزيارة موقع الشرطة الفيدرالية",
                ctpsTitle: "CTPS",
                ctpsText: "بطاقة العمل والضمان الاجتماعي إلزامية للحصول على عمل منظم.",
                registroTitle: "السجل الوطني للأجانب",
                registroText: "يعادل RG (السجل العام)",
                residenciaTitle: "إقامة دائمة",
                residenciaText: "تم الاستحواذ عليها بعد 4 سنوات من العيش في البرازيل",
                vistoTitle: "تأشيرة مؤقتة وتصريح إقامة للسوريين",
                vistoText: "الحصول على التأشيرة المؤقتة وتصريح الإقامة للاجئين السوريين المتضررين من النزاعات المسلحة السورية على الحق في تصريح إقامة بطريقة أكثر بساطة.",
        },
        jobs: {
                titlePage: "وظائفالوظائف والدخل",
                subtitlePage: "هنا معلومات عن مكان البحث عن وظيفة في ريو دي جانيرو",
                linkPage: "العودة إلى الصفحة السابقة",
                linkText: "انقر هنا لزيارة الموقع المسؤول",
                ministerioTitle: "وزارة العمل والتشغيل",
                escolaTitle: "مدرسة العمال و PRONATEC",
                sugestoesTitle: "بعض الاقتراحات الأخرى",
                trabalhaBrasilTitle: "عمل البرازيل",
                manPowerGroupTitle: "مجموعة مان باور",
                totiTitle: "توتي",
                somamosTitle: "نضيف",
                sineTitle: "SINE - نظام التوظيف الوطني",
                jovemTitle: "برنامج المتدربين الشباب في ريو دي جانيرو"
        },
        community: {
                titlePage: "تواصل اجتماعي",
                subtitlePage: "ستجد هنا العديد من المجتمعات المنتشرة في جميع أنحاء ريو دي جانيرو",
                linkPage: "العودة إلى الصفحة السابقة",
                juntosTitle: "الآن معًا",
                refoodgesTitle: "Refoodges",
                refoodgesText: "يتلقى اللاجئون المشاركون في فن الطهو الدعم من أجل الاحتراف والاندماج في سوق العمل.",
                tamojuntasTitle: "تامو جوينتس",
                aspasTitle: "أبرشية ASPAS في Duque de Caxias (ASPAS)",
                aspasText: "كيان اجتماعي وخيري ، من أبرشية دوكي دي كاكسياس ، مسؤول عن الأعمال الاجتماعية والمشاريع المجتمعية.",
                outrosTitle: "بعض الاقتراحات الأخرى:",
                outrosLinkName1: "جمعية سانت فنسنت دي بول",
                linkName: "انقر هنا لزيارة الموقع",
        },
        legal: {
                titlePage: "مساعدة قانونية",
                subtitlePage: "ستجد هنا معلومات عن مواقع المساعدة القانونية",
                linkName: "العودة إلى الصفحة السابقة",
                acnurTitle: "مفوض الأمم المتحدة السامي لشؤون اللاجئين",
                acnurLinkName: "انقر هنا لزيارة موقع المفوضية السامية للأمم المتحدة لشؤون اللاجئين",
                caritasTitle: "كاريتاس",
                caritasText: "برنامج الخدمة للاجئين وطالبي اللاجئين",
                caritasLinkName: "انقر هنا لزيارة موقع كاريتاس الإلكتروني",
                cepremiTitle: "CEPREMI- تنظيم المجتمع",
                cepremiText: "منظمة مخصصة لتقديم المساعدة القانونية لطالبي اللجوء واللاجئين والمهاجرين ، ومقرها في ريو دي جانيرو. ومع ذلك ، فإننا نقدم المشورة القانونية للمهاجرين في جميع أنحاء البلاد.",
                conareTitle: "الكوناري (اللجنة الوطنية للاجئين)",
                conareLinkName: "انقر هنا لمعرفة المزيد عن كوناري",
                coordenacaoTitle: "التنسيق العام للهجرة",
                coordenacaoLinkName: "انقر هنا لمعرفة المزيد",
                migranteTitle: "ميجرانتويب",
                migranteLinkName: "انقر هنا لمعرفة المزيد",
                refugiadosTitle: "مركز خدمة اللاجئين",
                refugiadosText: "يساعد مركز خدمة اللاجئين في التوثيق وإجراءات الدعم الاجتماعي للعائلات.",
                ruiTitle: "مؤسسة روي باربوسا",
                ruiLinkName: "انقر لعرض الموقع",
                crasTitle: "CRAS - المركز المرجعي للمساعدة الاجتماعية",
                crasText: "الوحدة المرجعية العامة للولاية لتقديم خدمات المساعدة الاجتماعية وبرامج ومزايا الحماية الاجتماعية الأساسية المقدمة بموجب نظام المساعدة الاجتماعية الموحد SUAS في بلدية ريو دي جانيرو.",
                crasLinkName: "انقر هنا لمعرفة المزيد"
        },
        shelters: {
                titlePage: "الملاجئ",
                subtitlePage: "ستجد هنا أماكن لإيواء اللاجئين في ريو دي جانيرو",
                linkName: "العودة إلى الصفحة السابقة",
                linkName2: "انقر لرؤية المواقع",
                abrigosTitle: "الملاجئ",
                abrigosText: "المأوى هو مكان إقامة مؤقت يقدمه أحيانًا عامة الناس أو الحكومة نفسها. حاول دائمًا التعرف على تنظيم الملجأ. فيما يلي بعض عناوين المأوى التي أشار إليها محرك بحث Google. لديك أيضًا إمكانيات أخرى للبحث عن مكان للعيش فيه.",
                republicasTitle: "الجمهوريات",
                republicasText: "تستهدف الأشخاص الذين غادروا وحدات الاستقبال والشوارع.",
                defensoriaTitle: "الدفاع العام",
                defensoriaText: "يضمن الحق في السكن اللائق ويمثل المواطنين ذوي الدخل المنخفض في الأمور المتعلقة بالسكن.",
                defesaTitle: "الدفاع المدني",
                defesaText: "الوقاية من الكوارث وإجراءات المساعدة",
                casaTitle: "بيتي ، حياتي",
                casaText: "برنامج الإسكان الشعبيإذا كنت ترغب في شراء منزل ، فإن حكومتنا لديها مشروع يسمى Minha Casa Minha Vida. حيث يمكنك تحقيق حلمك بامتلاك منزل بسعر معقول. لمعرفة المزيد ، ابحث عن بنك يسمى Caixa Econômica Federal أو ما نسميه Imobiliária (مكان بيع العقارات واستئجارها)",
                acolhidaVTitle: "مرحبا بك في بابا فرانسيسكو",
                acolhidaVText: "الشراكة مع برنامج باريس كاريتاس",
                acolhidaVLinkName: "انقر لمعرفة المزيد",
                creasTitle: "كرياس - المركز المرجعي المتخصص للمساعدة الاجتماعية",
                creasText: "الوحدة العامة للدولة ذات التغطية البلدية التي يتمثل دورها في أن تكون نقطة مرجعية ، في المناطق ، لتوفير العمل الاجتماعي المتخصص للعائلات والأفراد في حالات الخطر الشخصي أو الاجتماعي ، بسبب انتهاك الحقوق.",
                creasLinkName: "انقر هنا لزيارة الموقع"
        },
        emergency: {
                titlePage: "حالة طوارئ",
                subtitlePage: "ستجد هنا جهات اتصال لخدمات الطوارئ في ريو دي جانيرو",
                linkName: "العودة إلى الصفحة السابقة",
                linkName2: "انقر هنا لرؤية المواقع",
                mulheresTitle: "وحدة نسائية",
                mulheresText: "اتصل على 180.",
                policiaTitle: "شرطة",
                policiaText: "اتصل ب 911.",
                ambulanciaTitle: "الإسعاف العام",
                ambulanciaText: "اتصل بـ 192.",
                bombeirosTitle: "قسم الأطفاء",
                bombeirosText: "اتصل بـ193.",
                saudeTitle: "اتصل بخط الصحة",
                saudeText: "اتصل بالرقم 136. من الاثنين إلى الجمعة (باستثناء أيام العطل). يقبل SUS.",
                ubsTitle: "UBS - وحدة الصحة الأساسية",
                ubsText: "يقدم استشارة مع ممارس عام.",
                upaTitle: "UPA - وحدة رعاية الطوارئ",
        },
        restaurant: {
                titlePage: "مطاعم",
                subtitlePage: "ستجد هنا مواقع مطاعم في ريو دي جانيرو",
                linkPage: "العودة إلى الصفحة السابقة",
                popularTitle: "مطاعم شعبية",
                popularText: "هذه هي المطاعم حيث تكلف الوجبة من 0.50 ريال برازيلي إلى جانب كونها رخيصة فهي لذيذة للغاية.",
                popularLinkName: "انقر هنا لرؤية المطاعم"
        },
        education: {
                titlePage: "التعليم",
                subtitlePage: "هنا يمكنك العثور على مواقع المدارس والجامعات في ريو دي جانيرو",
                linkPage: "العودة إلى الصفحة السابقة",
                linkName: "انقر هنا لمعرفة المزيد",
                catedraTitle: "كرسي سيرجيو فييرا دي ميلو",
                caritasTitle: "كاريتاس - لاجئون في المدارس",
                pantheonTitle: "بانثيون - تعليم اللاجئين الكونغوليين في دوكي دي كاكسياس",
                seeducTitle: "سيدوك آر جيه",
                smeTitle: "SME - إدارة التعليم البلدية",
                smeLinkName: "www.rio.rj.gov.br/web/sme",
                linkName2: "انقر لرؤية الموقع",
                csvmTitle: "الجامعات التابعة للمفوضية السامية للأمم المتحدة لشؤون اللاجئين"
        }
}