export default {
    policy: {
        p: "Nous utilisons nos propres cookies et ceux de tiers qui vous reconnaissent et vous identifient en tant qu'utilisateur unique, pour assurer une bonne expérience de navigation et améliorer les performances de notre site Web. En poursuivant votre navigation sur notre site, vous acceptez l'utilisation de cookies. Pour en savoir plus, visitez notre ",
        a: "page des conditions d'utilisation des cookies",
        btn: "continuer et fermer"
    },
    toast: {
        h1: "Données envoyées avec succès !",
        h12: "Merci pour le retour"
    },
    terms: {
        h2: "Conditions d'utilisation des cookies",
        p1: "Un cookie est un petit texte envoyé au navigateur par le site Web que vous visitez. Avec lui, le site Web se souvient des informations relatives à la visite, ce qui facilite votre prochain accès et rend le site Web plus utile pour vous. Par exemple, nous utilisons des cookies. pour mémoriser votre langue préférée, stocker et protéger vos données grâce à des formulaires insérés sur notre site Internet. Il est possible de désactiver l'utilisation de cookies dans les paramètres de votre navigateur. ",
        span: "Quelles données collectons-nous?",
        p2: "- Préférence de langue;",
        p3: "- Informations du formulaire de contact;"
    },
    header: {
        li1: "Accueil",
        li2: "Rencontrez le projet",
        li3: "Assistance aux réfugiés",
        li4: "Contact",
    },
    banner: {
        btn: "Rencontrez le projet"
    },
    contact: {
        pForm: "Si vous êtes un réfugié, remplissez le formulaire ci-dessous et nous vous aiderons.",
        spanNome: "Quel est votre nom? (facultatif)",
        spanPais: "Quel est votre pays d'origine? (obligatoire)",
        spanIdade: "Quel âge avez-vous (obligatoire)",
        spanTel: "Entrez votre numéro de téléphone (facultatif):",
        spanEmail: "Entrez votre email (facultatif):",
        spanGender: "Quel est votre sexe ? (Obligatoire)",
        spanGender2: "À quel genre vous identifiez-vous le plus ? (Obligatoire)",
        genderOpt1: "HOMME",
        genderOpt2: "FEMME",
        genderOpt3: "LGBT",
        btnSubmit: "Soumettre",
        pWhatsApp: "ou contactez-nous directement via WhatsApp.",
        linkWhatsApp: "Ouvrir WhatsApp",
        pPartner: "Site partenaire:",
        confirmToast: "Envoyé avec succès !"
    },
    feedback: {
        h1: " ÉVALUER NOTRE SITE ",
        btn: "ENVOYER",
        rate5: "J'ai vraiment aimé le site ! Beau et facile à trouver les informations !",
        rate4: "Très bonne accessibilité, convivialité et design !",
        rate3: "Bonne information, conception et accessibilité.",
        rate2: "La conception et l'information peuvent s'améliorer...",
        rate1: "Informations utiles...",
        placeholder: "Ecrivez votre opinion ici...",
        default: "Choisissez au moins une étoile ;)",
        alert: "Merci pour vos commentaires ! ;)"
    },
    footer: {
        pFooter: "© 2021 RioRefugees. Développé par ",
        aName: "Retour en haut"
    },
    about: {
        h1: "NOTRE INITIATIVE",
        p: "Rio Refugees est une initiative qui découle de la vision de la nécessité de rapprocher les réfugiés de Rio de Janeiro. Comme une maison aux portes ouvertes ou un guide pour que la personne sache où aller.",
        link: "En savoir plus"
    },
    refugeesHistory: {
        h1: "QUI AIDE-NOUS?",
        p1: "La mairie de la ville de Rio de Janeiro, par le décret 50.187 du 14/02/22, a créé le Comité des politiques publiques pour les réfugiés, les immigrés et les apatrides de la municipalité de Rio de Janeiro (Compar-Rio), dans le but de défendre et de promouvoir les droits humains des personnes dans de telles situations. Compar-Rio sera sous la direction exécutive de la Coordination des droits de l'homme du Secrétariat à la citoyenneté.",
        p2: "Les personnes bénéficiant du programme d'assistance aux réfugiés qui répondent aux critères établis par le décret recevront un montant de R$ 600,00 reais pendant 3 mois maximum. L'aide peut être reconduite pour la même période dans des situations d'extrême vulnérabilité socio-économique. L'inclusion dans d'autres prestations d'assistance n'empêche pas les personnes de participer au programme d'assistance aux réfugiés, à condition qu'elles remplissent les conditions nécessaires (HCR, 2022).",
        span2: "RÉFUGIÉS",
        p3: " Il s'agit de personnes qui se trouvent hors de leur pays d'origine en raison de craintes fondées de persécutions liées à des questions de race, de religion, de nationalité, d'appartenance à un groupe social ou d'opinion politique particulier, ainsi que de violations graves et généralisées des droits de l'homme et des conflits."
    },
    lastNotices: {
		h1: "DERNIÈRES NOUVELLES",
		prefeituraTitle: "Actualités du 25/02/2022",
		prefeituraTexto: "La mairie de la ville de Rio de Janeiro, par le décret 50.187 du 14/02/22, a créé le Comité des politiques publiques pour les réfugiés, les immigrés et les apatrides de la municipalité de Rio de Janeiro (Compar-Rio), dans le but de défendre et de promouvoir les droits humains des personnes dans de telles situations. Compar-Rio sera sous la direction exécutive de la Coordination des droits de l'homme du Secrétariat à la citoyenneté. Les personnes bénéficiant du programme d'assistance aux réfugiés qui répondent aux critères établis par le décret recevront un montant de R$ 600,00 reais pendant 3 mois maximum. L'aide peut être reconduite pour la même période dans des situations d'extrême vulnérabilité socio-économique. L'inclusion dans d'autres prestations d'assistance n'empêche pas les personnes de participer au programme d'assistance aux réfugiés, à condition qu'elles remplissent les conditions nécessaires (HCR, 2022).",
		linkName: "Retrouvez l'intégralité de l'actualité en cliquant ici"
	},
    aboutView: {
        h1: "A PROPOS DU PROJET",
        h21: "À PROPOS DE NOUS",
        p1: "RioRefugees est une initiative qui découle de la vision de la nécessité de rapprocher les réfugiés de Rio de Janeiro. Comme une maison aux portes ouvertes ou un guide pour que la personne sache où aller. Quitter la chambre des maîtres en développement Emplacement d'Unisuam , cette initiative est une combinaison d'affection et de connaissances pour le réfugié. Nous pensons qu'en offrant des informations importantes relatives à Rio de Janeiro, telles que: Documentation, éducation, santé, travail, logement et culture, dans les langues du principal flux migratoires, nous contribuons à l'insertion locale, à la réduction des barrières linguistiques et à plus d'autonomie sur le nouveau territoire. ",
        h22: "NOTRE MISSION",
        p2: "Orienter les personnes en situation de refuge vers des informations importantes, afin de réduire leur vulnérabilité.",
        h23: "VISION DU FUTUR",
        p3: "Etre une référence dans les outils de recherche pour les personnes en situation de refuge, en besoin d'accompagnement dans les contextes les plus importants pour leur qualité de vie, en essayant toujours de surmonter les barrières linguistiques.",
        h24: "AUTEUR DU PROJET",
        h25: "PROF MARIA ISABEL",
        p4: "Étudiant à la maîtrise en développement local par le programme de troisième cycle en développement local au Centro Universitário Augusto Motta, UNISUAM. Diplôme en administration des affaires, spécialisation en ressources humaines et enseignement dans l'enseignement supérieur. Enseignant suppléant au Colégio Federal Pedro II et instructeur au SENAC RJ. Il a une expérience dans le domaine de l'administration, agissant sur les sujets suivants : gestion du marketing, gestion de la production, gestion des personnes et entrepreneuriat, recherche et innovation.",
    },
    refugeesMenu: {
        h2: "ASSISTANCE AUX RÉFUGIÉS",
        p: "Sur cette page, vous pouvez trouver des informations utiles pour la vie quotidienne en tant que citoyen de Rio de Janeiro.",
        namePage: "En savoir plus",
        document: "DOCUMENTS",
        jobs: "EMPLOI ET REVENUS",
        community: "COMMUNAUTÉS",
        legal: "ASSISTANCE LÉGALE",
        shelters: "ABRIS",
        emergency: "URGENCE",
        restaurants: "RESTAURANTS",
        education: "ÉDUCATION"
    },
    document: {
        titlePage: "DOCUMENT",
        subtitlePage: "Voici des informations sur les documents nécessaires aux citoyens de Rio de Janeiro",
        link: "Retour à la page précédente",
        linkName: "Cliquez pour en savoir plus",
        refugioTitle: "Protocole de demande de statut de réfugié",
        refugioText: "Le protocole de demande de statut de réfugié, l'identité des réfugiés et des demandeurs d'asile, prouve la situation juridique dans le pays et autorise le CTPS à expédier. Il est délivré par la police fédérale.",
        refugioLinkName: "Cliquez ici pour visiter le site Web de la police fédérale",
        ctpsTitle: "CTPS",
        ctpsText: "La carte d'emploi et de sécurité sociale est obligatoire pour obtenir un travail régularisé.",
        registroTitle: "Registre national des étrangers",
        registroText: "Equivalent à RG (registre général)",
        residenciaTitle: "Résidence permanente",
        residenciaText: "Acquis après 4 ans de résidence au Brésil",
        vistoTitle: "Visa temporaire et permis de séjour pour les Syriens",
        vistoText: "Les visas et permis de séjour temporaires pour les réfugiés syriens touchés par les conflits armés syriens ont droit à un permis de séjour d'une manière plus simplifiée.",
    },
    jobs: {
        titlePage: "EMPLOI ET REVENUS",
        subtitlePage: "Voici des informations sur où chercher un emploi à Rio de Janeiro",
        linkPage: "Revenir à la page précédente",
        linkText: "Cliquez ici pour visiter le site Web responsable",
        MinisterioTitle: "Ministère du Travail et de l'Emploi",
        escolaTitle: "Ecole ouvrière et PRONATEC",
        trabalhaBrasilTitle: "travail Brésil",
        manPowerGroupTitle: "Groupe de puissance de l'homme",
        totiTitle: "Toti",
        somamosTitle: "nous ajoutons",
        sineTitle: "SINE - Système National de l'Emploi",
        jovemTitle: "Programme de jeunes apprentis à Rio de Janeiro"
    },
    community: {
        titlePage: "COMMUNAUTÉ",
        subtitlePage: "Ici vous pouvez trouver plusieurs communautés réparties dans Rio de Janeiro",
        linkPage: "Revenir à la page précédente",
        juntosTitle: "Maintenant ensemble",
        refoodgesTitle: "Refoodges",
        refoodgesText: "Les réfugiés impliqués dans la gastronomie bénéficient d'un accompagnement de la professionnalisation et de l'insertion sur le marché du travail.",
        tamojuntasTitle: "Tamo Juntas",
        aspasTitle: "ASPAS Diocèse du Duque de Caxias (ASPAS)",
        aspasText: "Entité sociale et philanthropique, du diocèse de Duque de Caxias, responsable des œuvres sociales et des projets communautaires.",
        outrosTitle: "Autres suggestions:",
        outrosLinkName1: "Association Saint Vincent de Paul",
        linkName: "Cliquez ici pour visiter le site",
    },
    legal: {
        titlePage: "ASSISTANCE JURIDIQUE",
        subtitlePage: "Ici vous pouvez trouver des informations sur les lieux d'assistance juridique",
        linkName: "Retour à la page précédente",
        acnurTitle: "Haut-Commissariat des Nations Unies pour les réfugiés",
        acnurLinkName: "Cliquez ici pour visiter le site Web du HCR",
        caritasTitle: "Caritas",
        caritasText: "Programme de service pour les réfugiés et les demandeurs de réfugiés",
        caritasLinkName: "Cliquez ici pour visiter le site Web de Caritas",
        cepremiTitle: "CEPREMI- Organisme communautaire",
        cepremiText: "Organisation dédiée à l'assistance juridique aux demandeurs d'asile, réfugiés et immigrants, dont le siège est à Rio de Janeiro. Cependant, nous fournissons des conseils juridiques aux migrants à travers le pays.",
        conareTitle: "CONARE (Comité national pour les réfugiés)",
        conareLinkName: "Cliquez ici pour en savoir plus sur Conare",
        coordenacaoTitle: "Coordination générale de l'immigration",
        coordenacaoLinkName: "Cliquez ici pour en savoir plus",
        migranteTitle: "Migrante Web",
        migranteLinkName: "Cliquez ici pour en savoir plus",
        refugiadosTitle: "Centre de service aux réfugiés",
        refugiadosText: "Le Centre de service aux réfugiés aide à la documentation et aux procédures de soutien social pour les familles.",
        ruiTitle: "Maison de fondation de Rui Barbosa",
        ruiLinkName: "Cliquez pour afficher l'emplacement",
        crasTitle: "CRAS – Centre de Référence de l'Aide Sociale",
        crasText: "Unité publique de référence de l'État pour la fourniture de services d'assistance sociale, de programmes et de prestations de protection sociale de base offerts dans le cadre du système unique d'assistance sociale SUAS dans la municipalité de Rio de Janeiro.",
        crasLinkName: "Cliquez ici pour en savoir plus"
    },
    shelters: {
        titlePage: "ABRIS",
        subtitlePage: "Ici vous trouverez des emplacements pour les abris de réfugiés à Rio de Janeiro",
        linkName: "Retour à la page précédente",
        linkName2: "Cliquez pour voir les emplacements",
        abrigosTitle: "Abrigos",
        abrigosText: "Le refuge est un lieu de résidence temporaire qui est parfois offert par des gens ordinaires ou par le gouvernement lui-même. Essayez toujours de vous renseigner sur l'organisation du refuge. Voici quelques adresses de refuges indiquées par le moteur de recherche Google. Vous avez aussi d'autres possibilités pour chercher un endroit où vivre.",
        republicasTitle: "Républiques",
        republicasText: "Destiné aux personnes qui ont quitté les unités d'accueil et les rues.",
        defensoriaTitle: "Défense publique",
        defensoriaText: "Il garantit le droit à un logement décent et représente les citoyens à faible revenu dans les questions liées au logement.",
        defesaTitle: "Protection civile",
        defesaText: "Actions de prévention et d'assistance aux catastrophes",
        casaTitle: "Ma maison, ma vie",
        casaText: "Si vous voulez acheter une maison, notre gouvernement a un projet appelé Minha Casa Minha Vida. Vous pouvez y réaliser votre rêve de posséder une maison à un prix plus abordable. Pour en savoir plus, recherchez une banque appelée Caixa Econômica Federal ou ce que nous appelons Imobiliária (lieu où les propriétés sont vendues et louées)",
        acolhidaVTtitle: "Le pape François souhaite la bienvenue à la maison",
        acolhidaVText: "Partenariat avec le programme PARES Cáritas",
        acolhidaVLinkName: "Cliquez pour en savoir plus",
        creasTitle: "CRÉAS - Centre de référence spécialisé en assistance sociale",
        creasText: "Unité publique d'État à couverture communale dont le rôle est d'être un lieu de référence, dans les territoires, pour l'offre d'un travail social spécialisé aux familles et aux personnes en situation de risque personnel ou social, en raison de violation des droits.",
        creasLinkName: "Cliquez ici pour visiter le site"
    },
    emergency: {
        titlePage: "URGENCE",
        subtitlePage: "Ici vous pouvez trouver des contacts pour les services d'urgence à Rio de Janeiro",
        linkName: "Retour à la page précédente",
        linkName2: "Cliquez ici pour voir les emplacements",
        mulheresTitle: "Unité pour les femmes",
        mulheresText: "Appelez le 180.",
        policiaTitle: "Police",
        policiaText: "Appelez le 190.",
        ambulanciaTitle: "Ambulance publique",
        ambulanciaText: "Appeler le 192.",
        bombeirosTitle: "Service d'incendie",
        bombeirosText: "Appelez le 193.",
        saudeTitle: "État de la numérotation",
        saudeText: "Appeler le 136. Du lundi au vendredi (sauf les jours fériés). SUS accepté.",
        ubsTitle: "UBS - Unité de santé de base",
        ubsText: "Offre une consultation avec un médecin généraliste.",
        upaTitle: "UPA - Unité de soins d'urgence",
    },
    restaurant: {
        titlePage: "RESTAURANTS",
        subtitlePage: "Ici vous pouvez trouver des restaurants à Rio de Janeiro",
        linkPage: "Revenir à la page précédente",
        popularTitle: "Restaurants populaires",
        popularText: "Ce sont des restaurants où le repas coûte à partir de 0,50 R$ et en plus d'être bon marché, il est très savoureux.",
        popularLinkName: "Cliquez ici pour voir les restaurants"
    },
    education: {
        titlePage: "ÉDUCATION",
        subtitlePage: "Ici vous pouvez trouver les établissements scolaires et universitaires à Rio de Janeiro",
        linkPage: "Revenir à la page précédente",
        linkName: "Cliquez ici pour en savoir plus",
        catedraTitle: "Cátedra Sérgio Vieira de Mello",
        caritasTitle: "Caritas - Réfugiés dans les écoles",
        pantheonTitle: "Panthéon - Education des réfugiés congolais à Duque de Caxias",
        seeducTitle: "Seeduc RJ",
        smeTitle: "PME - Service municipal de l'Éducation",
        smeLinkName: "www.rio.rj.gov.br/web/sme",
        linkName2: "Cliquez pour voir l'emplacement",
        csvmTitle : "Universités affiliées au HCR"
    }
}