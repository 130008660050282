export default {
	policy: {
		p: "Utilizamos cookies próprios e de terceiros que o reconhecem e identificam como usuário único, para garantir uma boa experiência de navegação e melhorar o desempenho de nosso site. Ao continuar navegando em nosso site, você concorda com o uso de cookies. Para saber mais, acesse nossa ",
		a: "página de termos de uso dos cookies",
		btn: "continuar e fechar"
	},
	toast: {
		h1: "Dados enviados com sucesso!",
		h12: "Obrigado pelo feedback"
	},
	terms: {
		h2: "Termos do uso de cookies",
		p1: "Um cookie é um pequeno texto enviado ao navegador pelo site que você visita. Com ele, o site lembra das informações sobre a visita, o que facilita seu próximo acesso e deixa o site mais útil para você. Por exemplo, usamos cookies para lembrar seu idioma preferido, armazenar e proteger seus dados através de formulários inseridos em nosso site. É possivel desabilitar o uso de cookies nas configurações do navegador.",
		span: "Quais dados coletamos?",
		p2: "- Preferência de idioma;",
		p3: "- Informações do formulário de contato;"
	},
	header: {
		li1: "Inicio",
		li2: "Conheça o Projeto",
		li3: "Assistência ao refugiado",
		li4: "Contato",
	},
	banner: {
		btn: "Conheça o Projeto"
	},
	contact: {
		pForm: "Se você é um refugiado, preencha o formulário abaixo que iremos te ajudar.",
		spanNome: "Qual seu nome? (opcional)",
		spanPais: "Qual seu País de origem? (obrigatório)",
		spanIdade: "Qual é sua idade? (obrigatório)",
		spanTel: "Informe seu número de telefone (opcional):",
		spanEmail: "Informe seu email (opcional):",
		spanGender: "Qual seu gênero? (Obrigatório)",
		spanGender2: "Qual gênero você mais se identifica? (Obrigatório)",
		genderOpt1: "HOMEM",
		genderOpt2: "MULHER",
		genderOpt3: "LGBT",
		btnSubmit: "Enviar",
		pWhatsApp: "ou fale diretamente conosco através do WhatsApp.",
		linkWhatsApp: "Abrir WhatsApp",
		pPartner: "Site Parceiro:",
		confirmToast: "Enviado com sucesso!"
	},
	feedback: {
		h1: "AVALIE NOSSO SITE",
		btn: "ENVIAR",
		rate5: "Gostei muito do site! Bonito e fácil de achar as informações!",
		rate4: "Acessibilidade,  Usabilidade e design muito bons!",
		rate3: "Informações, Design e Acessibilidade boas.",
		rate2: "Design e informações podem melhorar...",
		rate1: "Informações pouco úteis...",
		placeholder: "Escreva aqui sua opinião...",
		default: "Escolha ao menos uma estrela ;)",
		alert: "Obrigado pelo seu feedback! ;)"

	},
	footer: {
		pFooter: "© 2021 RioRefugees. Desenvolvido por ",
		aName: "Voltar ao topo",
		terms: "Termos de Cookies"
	},
	about: {
		h1: "NOSSA INICIATIVA",
		p: "O Rio Refugees é uma iniciativa que surge a partir da visão da necessidade de aproximar mais os refugiados ao Rio de Janeiro. Como uma casa de portas abertas ou um guia para que a pessoa saiba para onde ir.",
		link: "Saiba mais"
	},
	refugeesHistory: {
		h1: "QUEM AJUDAMOS?",
		p1: " O deslocamento global de pessoas forçadas a deixar suas casas atingiu novo recorde na última década, o que mostra tendência de crescimento a cada ano.  Segundo o  relatório Tendências Globais – Deslocamento Forçado em 2021, divulgado pela Agência da Organização das Nações Unidas (ONU) para Refugiados (Acnur), o número de pessoas que tiveram que abandonar as suas casas saltou de 82, 4 milhões no ano de 2020 para 89,3 milhões no ano de 2021. Nível mais alto desde que começou a ser registrado (ACNUR, 2022).",
		p3: " São pessoas que estão fora de seu país de origem devido a fundados temores de perseguição relacionados a questões de raça, religião, nacionalidade, pertencimento a um determinado grupo social ou opinião política, como também devido à grave e generalizada violação de direitos humanos e conflitos armados.",
		span2: "REFUGIADOS",

	},
	lastNotices: {
		h1: "ÚLTIMAS NOTÍCIAS",
		prefeituraTitle: "Notícia de 25/02/2022",
		prefeituraTexto: "A Prefeitura da cidade do Rio de Janeiro, através do Decreto 50.187 em 14/02/22,  criou o Comitê de Políticas Públicas para Refugiados, Imigrantes e Apátridas do Município do Rio de Janeiro (Compar-Rio), com o propósito de defender e promover os direitos humanos das pessoas em tais situações. O Compar-Rio ficará sob a gerência executiva da Coordenação de Direitos Humanos da Secretaria de Cidadania. As pessoas beneficiárias do Programa de Auxílio ao Refugiado que se enquadrem nos critérios estabelecidos pelo decreto receberão um valor de R$ 600,00 reais por até 3 meses. O auxílio poderá ser prorrogado mais uma vez por igual período em situações de extrema vulnerabilidade socioeconômica. A inclusão em outros benefícios assistenciais não impede que as pessoas participem do Programa de Auxílio ao Refugiado, desde que preencham os requisitos necessários (ACNUR,2022).",
		linkName: "Veja a notícia completa clicando aqui"
	},
	aboutView: {
		h1: "SOBRE O PROJETO",
		h21: "SOBRE NÓS",
		p1: "O RioRefugees é uma iniciativa que surge a partir da visão da necessidade de aproximar mais os refugiados ao Rio de Janeiro. Como uma casa de portas abertas ou um guia para que a pessoa saiba onde ir. Saindo da sala de mestrado em desenvolvimento local da Unisuam, essa iniciativa é uma junção de carinho e conhecimento pela pessoa refugiada. Acreditamos que ao oferecer informações importantes relacionadas ao Rio de Janeiro, tais como: Documentação, educação, saúde, trabalho, moradia e cultura, nos idiomas dos principais fluxos  migratórios, contribuimos com a inserção local, redução de barreiras linguísticas e mais autonomia no novo território.",
		h22: "NOSSA MISSÃO",
		p2: "Direcionar as pessoas em situação de refúgio para encontrar informações importantes, afim de reduzir a sua vulnerabilidade.",
		h23: "VISÃO DO FUTURO",
		p3: "Ser uma referência em ferramentas de busca para as pessoas em situação de refúgio, com necessidade de direcionamento nos contextos mais importantes para sua qualidade de vida, tentando sempre ultrapassar as barreiras do idioma.",
		h24: "AUTORA DO PROJETO",
		h25: "PROFESSORA MARIA ISABEL",
		p4: "Mestranda em Desenvolvimento Local pelo Programa de Pós-Graduação em Desenvolvimento Local do Centro Universitário Augusto Motta, UNISUAM. Graduada em Administração de empresas, Especialização em RH e Docência no Ensino Superior. Professora substituta do Colégio Federal Pedro II e instrutora no SENAC RJ. Tem experiência na área de Administração, atuando principalmente nos seguintes temas: Gestão de Marketing, Gestão da Produção, Gestão de Pessoas e Empreendedorismo, Pesquisa e Inovação.",
	},
	refugeesMenu: {
		h2: "ASSISTÊNCIA AO REFUGIADO",
		p: "Nesta página, você poderá encontrar informações úteis para o dia a dia como cidadão do Rio de Janeiro.",
		namePage: "Saber mais",
		document: "DOCUMENTOS",
		jobs: "EMPREGO E RENDA",
		community: "COMUNIDADES",
		legal: "ASSISTÊNCIA JURÍDICA",
		shelters: "ABRIGOS",
		emergency: "EMERGÊNCIA",
		restaurants: "RESTAURANTES",
		education: "EDUCAÇÃO"
	},
	document: {
		titlePage: "DOCUMENTO",
		subtitlePage: "Aqui estão informações sobre documentos necessários ao cidadão no Rio de Janeiro",
		link: "Voltar à página anterior",
		linkName: "Clique para saber mais",
		refugioTitle: "Protocolo de solicitação de refúgio",
		refugioText: "Protocolo de solicitação de refúgio, identidade para os refugiados e solicitantes de refúgio, prova a situação legal no país e dá direito à expedição da CTPS. É emitido pela Polícia Federal.",
		refugioLinkName: "Clique aqui para visitar o site da polícia federal",
		ctpsTitle: "CTPS",
		ctpsText: "Carteira de trabalho e previdência social é obrogatório para obter um trabalho regularizado.",
		registroTitle: "Registro Nacional do Estrangeiro",
		registroText: "Equivalente ao RG (Registro Geral)",
		residenciaTitle: "Residência permanente",
		residenciaText: "Adquirida após 4 anos residindo no Brasil",
		vistoTitle: "Visto temporário e autorização de residência para Sírios",
		vistoText: "Visto temporário e autorização de residência para Sírios refugiados afetados pelos conflitos armados da Síria ganham direito à autorização de residência de forma mais simplificada.",
		caritasTitle: "Pares Cáritas",
		caritasText: "Programa de Atendimento a ​Refugiados e Solicitantes de Refúgio",
		caritasLink: "http://www.caritas-rj.org.br/",
		caritasLinkName: "Clique para acessar o site do Cáritas"
	},
	jobs: {
		titlePage: "EMPREGO E RENDA",
		subtitlePage: "Aqui estão informações sobre onde buscar emprego no Rio de Janeiro",
		linkPage: "Voltar à página anterior",
		linkText: "Clique aqui para visitar o site responsável",
		ministerioTitle: "Ministério do trabalho e emprego",
		escolaTitle: "Escola do trabalhador e PRONATEC",
		trabalhaBrasilTitle: "Trabalha Brasil",
		manPowerGroupTitle: "Man Power Group",
		totiTitle: "Toti",
		somamosTitle: "Somamos",
		sineTitle: "SINE - Sistema Nacional de Emprego",
		jovemTitle: "Programa Jovem Aprendiz no Rio de janeiro"
	},
	community: {
		titlePage: "COMUNIDADE",
		subtitlePage: "Aqui você encontra diversas comunidades espalhadas pelo Rio de Janeiro",
		linkPage: "Voltar à página anterior",
		juntosTitle: "Agora juntas",
		refoodgesTitle: "Refoodges",
		refoodgesText: "Refugiados envolvidos com gastronomia recebem suport de profissionalização e insersão no mercado de trabalho.",
		tamojuntasTitle: "Tamo Juntas",
		aspasTitle: "ASPAS Diocese de Duque de Caxias (ASPAS)",
		aspasText: "Entidade social e filantrópica, da diocese de Duque de Caxias responsável pelas obras sociais e projetos comunitários.",
		outrosTitle: "Algumas outras sugestões:",
		outrosLinkName1: "Associação São Vicente de Paulo",
		linkName: "Clique aqui para visitar o site",
	},
	legal: {
		titlePage: "ASSISTÊNCIA JURÍDICA",
		subtitlePage: "Aqui você encontra informações sobre localizações para assistência jurídica",
		linkName: "Voltar à página anterior",
		acnurTitle: "Alto comissário das Nações Unidas para refugiados",
		acnurLinkName: "Clique aqui para visitar o site do Acnur",
		caritasTitle: "Caritas",
		caritasText: "Programa de Atendimento a ​Refugiados e Solicitantes de Refúgio",
		caritasLinkName: "Clique aqui para visitar o site do Caritas",
		cepremiTitle: "CEPREMI- Organização comunitária",
		cepremiText: "Organização voltada para o atendimento juridico de solicitantes de refúgio, refugiados e imigrantes, cuja sede é no Rio de Janeiro. No entanto, prestamos consultoria jurídica a migrantes em todo o país.",
		conareTitle: "CONARE (Comitê Nacional para Refugiados)",
		conareLinkName: "Clique aqui para saber mais sobre o Conare",
		coordenacaoTitle: "Coordenação Geral de imigração",
		coordenacaoLinkName: "Clique aqui para saber mais",
		migranteTitle: "Migrante Web",
		migranteLinkName: "Clique aqui para saber mais",
		refugiadosTitle: "Centro de Atendimento aos Refugiados",
		refugiadosText: "O Centro de Atendimento aos Refugiados auxilia nos trâmites de documentação e apoio social às familias.",
		ruiTitle: "Fundação casa de Rui Barbosa",
		ruiLinkName: "Clique para ver a localização",
		crasTitle: "CRAS – Centro de Referência de Assistência Social",
		crasText: "Unidade pública estatal de referência para a oferta de serviços, programas e benefícios socioassistenciais de proteção social básica ofertado no âmbito do SUAS-Sistema Único de Assistência Social no município do Rio de Janeiro.",
		crasLinkName: "Clique aqui para saber mais"
	},
	shelters: {
		titlePage: "ABRIGOS",
		subtitlePage: "Aqui você encontra localizações para abrigos de refugiados no Rio de Janeiro",
		linkName: "Voltar à página anterior",
		linkName2: "Cliqui para ver as localizações",
		abrigosTitle: "Abrigos",
		abrigosText: "Abrigo é um lugar temporário de moradia que é oferecido às vezes por pessoas comuns ou pelo próprio governo. Procure sempre se informar sobre a organização do abrigo. Aqui tem alguns endereços de abrigos indicados pelo site de busca Google. Tem também outras possibilidades de procurar lugar de moradia.",
		republicasTitle: "Repúblicas",
		republicasText: "Voltada ao povo que saiu das unidades de acolhimento e das ruas.",
		defensoriaTitle: "Defensoria Pública",
		defensoriaText: "Garante o direito à moradia digna e representa os cidadãos de baixa renda em assuntos relacionados à moradia.",
		defesaTitle: "Defesa Civil",
		defesaText: "Prevenção de desastres e ações de assistências",
		casaTitle: "Minha casa, Minha vida",
		casaText: "Se quiser comprar uma casa nosso governo tem um projeto chamado Minha Casa Minha Vida. Nele voce pode conseguir realizar o sonho de ter uma casa pŕopriapor um preço mais acessível. Para saber mais, procure um banco chamado Caixa Econômica Federal ou o que chamamos de Imobiliária (lugar onde se vendem e alugam imóveis)",
		acolhidaVTitle: "Casa Acolhida Papa Francisco",
		acolhidaVText: "Parceria com o programa PARES Cáritas",
		acolhidaVLinkName: "Clique para saber mais",
		creasTitle: "CREAS - Centro de Referência Especializado de Assistência Social",
		creasText: "Unidade pública estatal de abrangência municipal que tem como papel constituir-se em lócus de referência, nos territórios, da oferta de trabalho social especializado a famílias e indivíduos em situação de risco pessoal ou social, por violação de direitos.",
		creasLinkName: "Clique aqui para visitar o site"
	},
	emergency: {
		titlePage: "EMERGÊNCIA",
		subtitlePage: "Aqui você encontra contatos dos serviços de emergencia do Rio de Janeiro",
		linkName: "Voltar à página anterior",
		linkName2: "Clique aqui para ver localizações",
		mulheresTitle: "Unidade para mulheres",
		mulheresText: "Ligue para 180.",
		policiaTitle: "Policia",
		politiaText: "Ligue para 190.",
		ambulanciaTitle: "Ambulância Pública",
		ambulanciaText: "Ligue para 192.",
		bombeirosTitle: "Corpo de Bombeiros",
		bombeirosText: "Ligue para 193.",
		saudeTitle: "Disque saúde",
		saudeText: "Ligue para 136. Funcionamento de segunda à sexta (exceto feriados). Aceita o SUS.",
		ubsTitle: "UBS - Unidade Básica de Saúde",
		ubsText: "Oferece consulta com um médico clinico geral.",
		upaTitle: "UPA - Unidade de Pronto Atendimento",
	},
	restaurant: {
		titlePage: "RESTAURANTES",
		subtitlePage: "Aqui você encontra localizações de restaurantes no Rio de Janeiro",
		linkPage: "Voltar à página anterior",
		popularTitle: "Restaurantes populares",
		popularText: "São restaurantes onde a refeição custa a partir de R$ 0,50 e além de barata é muito saborosa.",
		popularLinkName: "Clique aqui para ver os restaurantes"
	},
	education: {
		titlePage: "EDUCAÇÃO",
		subtitlePage: "Aqui você encontra localizações de escolas e faculdades no Rio de Janeiro",
		linkPage: "Voltar à página anterior",
		linkName: "Clique aqui para saber mais",
		catedraTitle: "Cátedra Sérgio Vieira de Mello",
		caritasTitle: "Cáritas - Refugiados nas escolas",
		pantheonTitle: "Pantheon - Educação para refugiados congoleses em Duque de Caxias",
		seeducTitle: "Seeduc RJ",
		smeTitle: "SME - Secretaria Municipal de Educação",
		smeLinkName: "www.rio.rj.gov.br/web/sme",
		linkName2: "Clique para ver a localização",
		csvmTitle: "Universidades conveniadas ACNUR"
	}
}